<template>

  <div class="cash">
    <i
        @click="$router.push('/admin_panel')"
        class="mdi mdi-folder-plus"></i>
    <div class="products">


      <h3 class="mb-2">Категории</h3>
      <div
          class="cash__categories mb-4"
      >
        <div

            v-for="(category,category_index) in categories" :key="category_index"
            @click="getProductsByCategory(category.id)"
        >
          <p   :class="{'cash__categories--item' :true, 'cash__categories--active': category_id === category.id  }">{{ category.name}} </p>
        </div>
      </div>
      <p class="good__name">Товары</p>
      <input  v-model="search" class="good__search" type="text" placeholder="Поиск...">
      <div class="products__menu">
        <div
            class="product__item"
            v-for="(product,product_index) in products"
            :key="product_index"
        >
          <div class="product__action">
            <i
                @click="openHistory(product.count_type,product.product_logs)"
                class="mdi mdi-history"
            >
            </i>
              <i
                  @click="openProductCount(product.id)"
                  class="mdi mdi-plus-box"
              >
              </i>
          </div>

          <p>{{ product.name }}</p>
          <p><b>Себестоимость: </b>{{ product.self_price }} тнг</p>
          <p><b>Розничная цена: </b>{{ product.price }} тнг</p>

          <p><b>Остаток:</b>&nbsp;{{ product.quantity }}
            <span v-if="product.count_type === 'count'"> шт</span>
            <span v-if="product.count_type === 'kg'"> кг</span>
            <span v-if="product.count_type === 'litr'"> литр</span>
          </p>
        </div>

      </div>

      <v-pagination
          v-if="pageCount"
          v-model="page"
          :length="pageCount"
          :total-visible="3"
      ></v-pagination>

      <v-dialog width="50%" v-model="show_history">
        <v-card >
          <div class="pa-4" v-for="(quantites,date) in history_logs" :key="date">
            <p> {{ date }}:</p>

            <p> <b>Продано: </b> {{ sumOfQuantites(quantites) }}</p>

            <p> <b>Прибыль: </b> {{  sumOfPrice(quantites) - sumOfSelfPrice(quantites)  }}</p>

          </div>
        </v-card>
      </v-dialog>

      <v-dialog width="50%" v-model="product_count_open">
        <v-card>
          <div class="pa-6">
            <v-text-field
                v-model="count"
                label="Количество"
                required
                outlined
                class="input"
                type="tel"
            ></v-text-field>
            <v-text-field
                v-model="self_price"
                label="Себестоимость"
                required
                outlined
                class="input"
                type="tel"
            ></v-text-field>
            <v-text-field
                v-model="price"
                label="Розничная цена"
                required
                outlined
                class="input"
                type="text"
            ></v-text-field>

            <v-btn
                class="primary"
                @click="saveProductCount"
            >
              Сохранить
            </v-btn>
          </div>
        </v-card>
      </v-dialog>
    </div>

  </div>
</template>


<script>
import moment from 'moment';
import { debounce } from 'lodash';

export default {
  name: "StockPage",
  data() {
    return {
      search: '',
      page:1,
      pageCount: '',
      history_logs: [],
      show_history: false,
      categories: [],
      payment_types: [],
      orders: [],
      products: [],
      selected_payment: '',
      amount_of_order: '',
      category_id: '',
      price_logs: [],
      self_price_logs: [],
      product_count_open : false,
      count: 0,
      product_id: '',
      self_price: 0,
      price: 0
    }
  },
  mounted() {
    this.getPayments()
    this.getCategories()
    //
    // this.orders = JSON.parse(localStorage.getItem('orders'))
    // this.products = JSON.parse(localStorage.getItem('products'))
    // console.log("products dfdfd",this.products)
    // console.log("orders 1111",localStorage.getItem('orders'))
  },
  computed: {
    groupedLogs() {
      return this.groupLogsByDate(this.products);
    }
  },
  methods: {
    saveProductCount() {

      if(this.price === 0 || !this.price) {
        this.$toast.open({
          message: 'Укажите розничную цену',
          type: "warning",
          position: "bottom",
          duration: 5000,
          queue: true,
        });
        return false;
      }
      if(this.self_price === 0 || !this.self_price) {
        this.$toast.open({
          message: 'Укажите себестоимость',
          type: "warning",
          position: "bottom",
          duration: 5000,
          queue: true,
        });
        return false;
      }

      let data = {
        count: this.count,
        product_id: this.product_id,
        price: this.price,
        self_price: this.self_price
      }
      this.$axios({
        method: 'post',
        url: `${this.$API_URL}product/count`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        data: data
      })
          .then(() => {
            this.$toast.open({
              message: 'Успешно сохранено',
              type: "warning",
              position: "bottom",
              duration: 5000,
              queue: true,
            });
            this.product_count_open = false
            this.getProductsByCategory(this.category_id)
          })
          .catch((error) => {
            console.log(error)
          });
    },
    openProductCount(product_id) {
      this.product_id = product_id
      this.product_count_open = true
    },
    searchProduct() {

      this.debounceSearchProduct();

    },
    debounceSearchProduct: debounce(function() {
      this.$axios({
        method: 'get',
        url: `${this.$API_URL}product?search=${this.search}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        }
      })
          .then((res) => {
            this.products = res.data.data
            this.pageCount = res.data.last_page
            localStorage.setItem('products',JSON.stringify(this.products))
          })
          .catch((error) => {
            console.log(error)
          });
    }, 1000),
    sumOfQuantites(quantities) {
      return (-1)* quantities.reduce((sum, item) => sum + item.quantity, 0);
    },
    sumOfPrice(quantities) {
      return quantities.reduce((sum, item) => sum + item.price, 0);
    },
    sumOfSelfPrice(quantities) {
      return quantities.reduce((sum, item) => sum + item.self_price, 0);
    },
    openHistory(count_type,logs) {
      this.show_history = true
      this.history_logs = this.groupLogsByDate(logs)

      this.price_logs = this.groupLogsByDate(logs)
      this.self_price_logs = this.groupLogsByDate(logs)
    },
    groupLogsByDate(logs) {

      return logs.reduce((acc, log) => {
        if (log && log.quantity !== undefined && log.created_at) {
          console.log("res", log);

          if (log.quantity < 0) {
            const date = moment(log.created_at).format('YYYY-MM-DD');
            if (!acc[date]) {
              acc[date] = [];
            }
            acc[date].push(log);
          }
        } else {
          console.error("Invalid log entry:", log);
        }
        return acc;
      }, {});


    },
    saveOrder() {

      if(!this.selected_payment) {
        this.$toast.open({
          message: 'Выберите тип оплаты',
          type: "warning",
          position: "bottom",
          duration: 5000,
          queue: true,
        });

      }

      const hasZeroAmount = this.orders.some(order => order.count_type !=='count' && order.amount_value === 0);

      if (hasZeroAmount) {
        this.$toast.open({
          message: 'Укажите вес или литр',
          type: "warning",
          position: "bottom",
          duration: 5000,
          queue: true,
        });
      }

      let data = {
        orders : this.orders,
        total: this.amount_of_order,
        payment_type: this.selected_payment
      };

      this.$axios({
        method: 'post',
        url: `${this.$API_URL}order`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        data: data
      })
          .then(() => {
            this.$toast.open({
              message: 'Успешно сохранено',
              type: "warning",
              position: "bottom",
              duration: 5000,
              queue: true,
            });
          })
          .catch((error) => {
            console.log(error)
          });
    },
    getAmountOfOrder() {
      console.log("result",this.orders)
      this.amount_of_order =  this.orders.reduce((total, order) => total + order.order_price ?? 0, 0);

    },
    deleteElement(id) {
      const index = this.orders.findIndex(order => order.id === id);
      if (index !== -1) {

        for (let i = 0; i < this.products.length; i++) {
          this.products[i].count_value = 1
          this.products[i].order_price = parseInt(this.products[i].price)
          this.products[i].amount_value = 0
        }

        this.orders.splice(index, 1);
      }
    },
    changeMass(order) {
      console.log(order)
      this.$set(order, 'order_price', order.amount_value*order.price);
    },
    addOrderCount(order) {

      if (!order.count_value)
        this.$set(order, 'count_value', 2);
      else
        order.count_value = order.count_value+1


      order.order_price = order.count_value*order.price
    },
    subOrderCount(order) {

      if (!order.count_value)
        this.$set(order, 'count_value', 1);
      else if(order.count_value === 1)
        order.count_value = 1
      else
        order.count_value = order.count_value-1

      order.order_price = order.order_price - 1*order.price
    },
    getProductsByCategory(id,page=null) {
      this.category_id = id
      if (!page) {
        page = 1
      }

      this.$axios({
        method: 'get',
        url: `${this.$API_URL}product?category_id=${this.category_id}&page=${page}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        }
      })
          .then((res) => {
            this.products = res.data.data

            console.log("info",res.data)
            this.pageCount = res.data.last_page
            localStorage.setItem('products',JSON.stringify(this.products))
          })
          .catch((error) => {
            console.log(error)
          });
    },
    getCategories() {
      this.$axios({
        method: 'get',
        url: `${this.$API_URL}category`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        }
      })
          .then((res) => {
            this.categories = res.data
          })
          .catch((error) => {
            console.log(error)
          });
    },
    addOrder(product) {


      this.$set(product, 'amount_value', 0);
      this.$set(product, 'count_value', 1);
      if (!product.order_price) {
        if(product.count_type==='count')
          this.$set(product, 'order_price', parseInt(product.price));
        else
          this.$set(product, 'order_price',0);
      }



      if (!this.orders.some(order => order.id === product.id)) {
        this.orders.push(product)
      }


    },
    getPayments() {
      this.$axios({
        method: 'get',
        url: `${this.$API_URL}payments`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        }
      })
          .then((res) => {
            console.log(res.data)
            this.payment_types = res.data
            console.log(this.payment_types)
          })
          .catch((error) => {
            console.log(error)
          });
    }
  },
  watch: {
    search(val) {
      if (val) {
        this.searchProduct();
      } else {
        this.getProductsByCategory(this.category_id,1);
      }
    },
    page(newPage) {
      this.getProductsByCategory(this.category_id, newPage);
    },
    orders: {
      handler() {
        console.log('Orders array changed');
        console.log('New total order price:', this.getAmountOfOrder());

        localStorage.setItem('orders',JSON.stringify(this.orders))
      },
      deep: true,
      immediate: true
    }
  }

};
</script>

<style>

p {
  padding: 0 !important;
  margin-bottom: 0 !important;
}

.cash {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-height: 100vh;

  .products {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    height: 100vh;

    .good__name {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 10px !important;
    }

    .good__search {
      padding: 10px;
      border: 1px solid black;
      margin-bottom: 20px;
    }


  }

}

.cassa {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
  padding: 20px;
  height: 100vh;
  overflow-y: scroll;

  .product__added {
    margin-bottom: 20px;
    position: relative;
    padding: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

    .product__name {
      margin-bottom: 10px !important;
      font-weight: bold;
    }

    .product__action {
      width: 100px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      margin-bottom: 10px !important;


      input {
        border-radius: 5px;
        padding: 5px;
        border: 1px solid black;
      }
      .product__plus {
        cursor: pointer;
        padding: 10px;
        background: #0069ff;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        border-radius: 5px;
        font-size: 18px;
      }
    }
  }
}


.payment {
  cursor: pointer;
  background: #0069ff;
  padding: 20px;
  border-radius: 5px;
  color: white;
  font-size: 20px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

}

.payment__types {

  .payment__type--title {
    font-size: 20px;
    font-weight: bold;
  }

  p {
    padding: 5px !important;
    margin-bottom: 2px !important;

  }
}

.product__delete {

  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  i {
    font-size: 18px;
    color: red;
  }
}

.product__order--item {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}


.cash__categories::-webkit-scrollbar {
  width: 1px;
}

.cash__categories::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
}

.cash__categories::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.cash__categories {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;

  .cash__categories--item {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 15px;
    padding: 30px !important;
    border-radius: 5px;

    border: 1px solid #0069ff;
  }
}

.products__menu {
  display: flex;
  flex-wrap: wrap;

  .product__item {
    padding: 25px;
    background: #0069ff;
    color: white;
    cursor: pointer;
    border-radius: 5px;

    border: 1px solid #ccc;
    margin-right: 10px;
    width: 210px;
    position: relative;

    p {
      font-size: 14px;
    }

    .product__action {
      font-size: 20px;
      position: absolute;
      right: 10px;
      bottom: 0;

      i {
        margin-right: 5px;
      }
    }
    i:hover {
      color: #f39c12;
    }
  }

}

.cash__categories--active {
  background: #ffc64a;
  color: white;
}

.product__action {
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>